@import "../../../styles/variables"
@import "../../../styles/breakpoints.scss"

.navBar
  display: flex
  justify-content: flex-end
  position: fixed
  height: 100%
  background: var(--wt-white3-dt-black1)
  box-shadow: var(--box-shadow-1)
  border-radius: 0px 40px 0 0px
  overflow-y: auto
  z-index: 2
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.row
  padding: 50px 22px 35px 29px
  top: 0
  left: 0
  display: flex
  flex-direction: column
  align-items: center
  z-index: 2

.nav
  padding-bottom: 25px
  margin-top: 75px

.logo
  display: flex
  justify-content: center
  align-items: center
  svg
    width: 154px
    height: 144px

@include media("<=Ltablet")
  .overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(0deg, rgba(234, 241, 249, 0.1), rgba(234, 241, 249, 0.1))
    backdrop-filter: blur(10px)
    opacity: 0
    z-index: -10
    transition: opacity $animationTime, z-index 0s $animationTime
    &.active
      opacity: 1
      z-index: 19
      transition: opacity $animationTime, z-index 0s 0s
  .navBar
    position: fixed
    top: 0
    left: 0
    z-index: 20
    transform: translateX(calc(-100% - 50px))
    transition: transform $animationTime
    &.active
      transform: translateX(0)
  .nav
    order: -1
    margin-top: 0
  .closeButton
    position: absolute
    top: 30px
    right: 22px
    width: 36px
    height: 36px
    z-index: 3
    svg
      path
        fill: var(--wt-blue3-dt-blue1)
    svg
      width: 17px
      height: auto
  .row
    padding-top: 100px
  //.last
    //display: none

@media screen and ( min-width: 1560px )
  .navBar
    // (100% - 1560px) - empty space on both sides from content
    // (100% - 1560px) / 2 - width of one side from content
    // (100% - 1560px) / 2 + 310px - empty space + content block left-padding
    // with this width navbar would be reight near content. So we need to add
    // margin to navbar. We need to subtract 46px - margin
    width: calc((100% - 1560px) / 2 + 310px - 46px)

.modalContent
  padding: 50px 70px
  max-width: 550px
  box-sizing: border-box
  text-align: center
  h2
    font-size: 1.714rem
    text-shadow: var(--text-shadow-2)
    color: var(--wt-blue3-dt-white2)
    margin: 0

.modalButtons
  display: flex
  align-items: center
  margin-top: 30px

.themeChangerText
  margin-left: 20px
  margin-right: auto
  font-family: Gilroy
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 19px
  color: #8f9bb2

.themeChanger
  display: flex
  flex-direction: row
  align-items: flex-end
  justify-content: space-between
  flex: 1
  width: 100%
  margin-top: auto
  margin-bottom: 14px
  svg
    width: 80px
    transform: translateY(6px)
    cursor: pointer
    margin-right: 10px
    &>g>g>circle
      transition: cx 0.1s
    &>g>path
      &:first-of-type
        transition: fill 0.1s
      &:last-of-type
        transition: fill 0.1s

.themeChangerLight
  svg
    &>g>g>circle
      cx: 20
    &>g>path
      &:first-of-type
        fill: #387EFF
      &:last-of-type
        fill: #11171F

.modalButton
  width: 170px
  &:first-of-type
    margin-right: 20px
    z-index: 3
@include media('<=tablet')
  .modalContent
    padding: 50px 40px

@include media('<=Lphone')
  .modalContent
    padding: 40px 20px
    h2
      max-width: 250px
      margin-left: auto
      margin-right: auto
  .modalButtons
    flex-direction: column
    margin-top: 25px
  .modalButton
    width: 100%
    max-width: 260px
    &:first-of-type
      margin-right: 0
      margin-bottom: 16px