@import "src/styles/variables"
@import "src/styles/breakpoints"

.menuWrapper
  position: relative

.menu
  z-index: 15
  margin-left: 13px
  height:44px
  overflow: visible
  display: inline-flex
  flex-direction: column
    
.mainBtn
  height: 44px
  box-sizing: border-box
  padding: 6px 9px 9px 9px

.btnDark
  background: #1D232C
  &::before, &::after
    display: none

.btn
  height: 44px
  box-sizing: border-box
  padding: 6px 9px 9px 9px
  border-radius: 0
  & > span
    display: block
    & > span
      flex: 1
      justify-content: space-between !important
  @include media('<=Lphone')
    & > span
      display: block
      & > span
        justify-content: center !important

  &:hover
    background-color: var(--wt-white12-dt-black1)
    & > *
      background-color: var(--wt-white12-dt-black1)
    & .btnTitle
      color: var(--wt-blue3-dt-white2)
    .inner svg path
      fill: var(--wt-blue3-dt-white2)
  &::before
    box-shadow: none
  &::after
    box-shadow: none

.btnTitle, .displayName
  margin-left: 15px
  white-space: nowrap
  margin-right: 5px

.btnTitle
  color: #8F9BB2
  
.displayName
  color: var(--wt-blue3-dt-white2)

.personImage
  width: 100%
  height: 100%
  overflow: hidden 
  background: $main
  border: 2px solid #EBEFFA
  border-radius: 50%
  width: 34px
  height: 34px
  box-sizing: border-box
  overflow: hidden
  object-fit: cover
  > img
    width: 100%
    height: 100%
    object-fit: cover

.inner, .innerUser
  width: 34px
  height: 34px
  display: flex
  justify-content: center
  align-items: center
  svg path
    fill: #8E9BB6

.innerUser
  svg path
    fill: var(--wt-blue3-dt-white2)

.menuLinks
  box-shadow: var(--box-shadow-5)
  visibility: hidden
  opacity: 0
  position: absolute
  transition: all .1s
  z-index: 15
  display: flex
  border-radius: 20px
  overflow: hidden
  flex-direction: column
  width: 180px
  top: 56px
  right: 0
  transition: opacity $animationTime
  &::before
    border-radius: 20px
    content: ""
    position: absolute
    top: -1px
    left: -1px
    bottom: -1px
    right: -1px
    background: linear-gradient(272.04deg, #D2DCEB -1.31%, #FFFFFF 98.94%)
    display: var(--lightDisplayBlock)
  @include media('<=Lphone')
    width: 80px

.menuContent
  background: var(--wt-white2-dt-black2)
  z-index: 10
  border-radius: 20px
  margin: 1px

.menuContentDarkTheme
  margin: none

.mainBtnDarkTheme
  padding: 0
  & > span
    padding: 6px 9px 9px 9px

.menuLinksActive
  opacity: 1
  visibility: visible

.basketInfo
  margin: 10px 10px 10px 10px 
  color: #8F9BB2
  font-weight: 600
  font-size: 1.143rem
  text-align: center

.hr
  background: #8F9BB2
  height: 1px
  margin: 5px 14px

  @media screen and ( min-width: 1560px )
  .navBar
    // (100% - 1560px) - empty space on both sides from content
    // (100% - 1560px) / 2 - width of one side from content
    // (100% - 1560px) / 2 + 310px - empty space + content block left-padding
    // with this width navbar would be reight near content. So we need to add
    // margin to navbar. We need to subtract 46px - margin
    width: calc((100% - 1560px) / 2 + 310px - 46px)