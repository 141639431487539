@import "src/styles/variables"
@import "src/styles/breakpoints"

.wrapper
  padding: 27px 0 30px 0

.heading
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  h1
    text-shadow: var(--text-shadow-2)
    font-size: 2.143rem

.buttonsWrapper
  display: flex
  position: relative
  padding-right: 85px

.languages
  position: absolute
  top: 1px
  right: 0

.btn
  width: 44px
  height: 44px
  svg path
    fill: var(--wt-blue3-dt-white2)
  &:not(:first-of-type)
    margin-left: 13px
  &.active
    position: relative
    &::after
      content: ''
      position: absolute
      top: -2px
      right: -2px
      width: 12px
      height: 12px
      background: #0B7BE1
      box-shadow: var(--box-shadow-18)
      border-radius: 50%
      z-index: 2

.button
  background: $main
  box-shadow: var(--box-shadow-16)
  border-radius: 10px
  display: flex
  justify-content: center
  align-items: center
  padding: 14px
  max-height: 44px
  box-sizing: border-box
  font-size: 1rem
  font-weight: 600
  color: var(--wt-blue3-dt-white2)
  text-decoration: none
  text-shadow: var(--text-shadow-2)
  margin-left: 13px

.breadCrumbs
  display: flex
  align-items: center
  flex-wrap: wrap
  margin-bottom: -8px

.breadCrumbsItem
  font-size: 0.857rem
  display: inline-flex
  align-items: center
  margin-bottom: 8px
  a, .breadCrumbsLink
    cursor: pointer
    color: var(--wt-gray1-dt-blue5)
    text-decoration: none
    display: inline-block
    padding: 10px 5px
    margin: -10px -5px
  svg
    margin-left: 10px
    margin-right: 10px

.breadCrumbsLinkActive
  font-weight: bold
  color: var(--wt-blue3-dt-white2) !important
  text-shadow: var(--text-shadow-4)

.buttons
  display: flex
  align-items: center
  padding-top: 1px
  padding-bottom: 1px
  height: 100%
  margin-right: 10px

.login
  font-size: 18px
  text-decoration: none

.signupButton
  height: 37px
  width: 120px
  margin-left: 23px

@include media('<=Ltablet')
  .buttonShowMenu
    padding: 5px
    margin: -5px
    border: none
    background-color: transparent
    box-shadow: none
    display: flex
    align-items: center
    height: 35px
    width: 35px
    outline: none
    > span
      width: 21px
      height: 2px
      border-radius: 5px
      background: var(--wt-blue3-dt-blue5)
      box-shadow: -1px -1px 2px var(--wt-white1-dt-blue5), 1px 1px 2px #A3AFC0
      position: relative
      &::before, &::after
        position: absolute
        content: ''
        border-radius: 5px
        background: var(--wt-blue3-dt-blue5)
        box-shadow: -1px -1px 2px var(--wt-white1-dt-blue5), 1px 1px 2px #A3AFC0
        height: 2px
        left: 0
      &::before
        top: -6px
        width: 25px
      &::after
        bottom: -6px
        width: 15px
  .buttonShowMenuDatkTheme
    > span
      box-shadow: none
      &::before, &::after
        box-shadow: none
  .heading
    justify-content: flex-start
    h1
      margin-left: 20px
  .buttonsWrapper
    margin-left: auto

@include media('<=tablet')
  .wrapper
    padding-top: 20px
  .heading
    flex-wrap: wrap
    h1
      margin-left: 0
  .head
    width: 100%
    order: 1
    margin-top: 25px

@include media('<=Lphone')
  .wrapper
    padding-top: 15px
    padding-bottom: 25px
  .person
    margin-right: 0
  .button
    padding: 8px
  .loginPhoneButton
    width: 100px
    height: 32px
    font-size: 1rem
    margin-top: 6px
    
@include media('<=phone')
  .btn
    &:not(:first-child)
      margin-left: 10px
  .button
    margin-left: 10px

@include media('<=Sphone')
  .wrapper
    padding-top: 10px