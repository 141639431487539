@import "../../styles/variables"
@import "../../styles/breakpoints"

// Card styles
.card
  width: 100%
  height: 100%
  position: relative
  padding: 1px
  &::before
    content: ''
    position: absolute
    border-radius: 20px
    top: 0
    left: 0
    bottom: 0
    right: 0
    z-index: 0
    background: var(--border-1)
    box-shadow: var(--box-shadow-4-outside)

// Inner content of the card
.content
  box-sizing: border-box
  background-color: var(--wt-white2-dt-black2)
  position: relative
  z-index: 3
  width: 100%
  height: 100%
  border-radius: 20px
  display: flex
  //justify-content: center
  //align-items: center
  transition: transform $animationTime

// If card is a link
.linked, .clickable:not(.reverse)
  &::after
    content: ''
    position: absolute
    border-radius: 20px
    transition: opacity $animationTime
  &::after
    top: 3px
    left: 3px
    right: 3px
    bottom: 3px
    border: 3px solid var(--wt-white2-dt-black2)
    box-shadow: var(--box-shadow-4-inside)
    z-index: 2
    opacity: 0
  &:active
    &::before
      opacity: 0
    &::after
      opacity: 1
    .content
      transform: translate($transformAmount, $transformAmount)
      background-color: transparent

.reverse
  &::after
    content: ''
    position: absolute
    border-radius: 20px
    transition: opacity $animationTime
    top: 1px
    left: 1px
    right: 1px
    bottom: 1px
    border: 3px solid var(--wt-white2-dt-black2)
    box-shadow: var(--box-shadow-4-inside)
    background-color: var(--wt-white2-dt-black2)
    z-index: 2
    opacity: 0
  &::before, &::after
    opacity: 1
  > .content
    background-color: transparent

.smallRadius
  &::before, &::after
    border-radius: 8px !important
  .content
    border-radius: 8px !important
  &.clickable
    &::after
      top: 0
      left: 0
      right: 0
      bottom: 0

.circled
  &::before, &::after
    border-radius: 50%
  .content
    border-radius: 50%

// Link inside the card
.link
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 4
  opacity: 0

@include media('<=phone')
  .noPhone.reverse
    &::after, &::before
      opacity: 0
    > .content
      background-color: transparent