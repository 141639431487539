@import "src/styles/variables"

.languages
  z-index: 15
  //@include media('<=Ltablet')
  //  right: 28px
  //  top: 1px
  //@include media('<=Sphone')
  //  right: 20px
  &::before
    content: ''
    position: absolute
    top: -1px
    left: -1px
    right: -1px
    bottom: -1px
    background: var(--border-7)
    box-shadow: var(--box-shadow-5)
    z-index: 1
    border-radius: 10px
  .languagesContent
    background-color: var(--wt-white2-dt-black2)
    z-index: 2
    position: relative
    border-radius: 10px
    overflow: hidden
  &:hover
    .notActive
      max-height: 45px
    .languageIcon
      transform: translateY(calc(-50%)) rotate(180deg)

.language
  display: block
  font-weight: 600
  font-size: 1.125rem
  border: none
  background-color: transparent
  box-shadow: none
  padding: 0
  outline: none
  //@include media('<=Lphone')
  //  font-size: 1rem

.languageInner
  padding: 12px 40px 12px 15px
  transition: background-color $animationTime
  cursor: pointer
  position: relative
  color: var(--wt-blue3-dt-white2)
  svg>g>path
    fill: var(--wt-blue3-dt-white2)
  //@include media('<=Lphone')
  //  padding: 5px 30px 5px 15px

.languageIcon
  position: absolute
  top: 50%
  transform: translateY(calc(-50% - 2px))
  transition: transform $animationTime
  right: 15px
  //@include media('<=Lphone')
  //  width: 12px
  //  height: auto
  //  right: 10px

.notActive
  max-height: 0
  overflow: hidden
  transition: max-height $animationTime
  .languageInner
    &:hover
      background-color: var(--wt-white11-dt-black1)