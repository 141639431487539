// Main colors
$menu: #F7F8F9
$main: #F1F3F6
$white: #ffffff
$gray-white: #EBEFFA

$text-dark: #31456A
$text-middle: #3D5E8B
$text-light: #8F9BB2
$text-disabled: #C2C6D3
$text-red: #EB7A7A
$text-blue: #0B7BE1

$status-new: #418F57
$status-pre-main: #6F72F1
$status-for-sale: #EF883D
$status-on-sale: #F43838

// Menu box shadow
$menu-box-shadow: 10px 10px 20px #DCE2E9

// Button colors
$button-color-primary: #163878

//
$input-color-success: linear-gradient(143.22deg, #418F57 64.53%, rgba(87, 164, 109, 0.99) 95.78%)

// Card box shadow
$card-border: linear-gradient(273.48deg, #D2DCEB -1.31%, #FFFFFF 98.94%)
$card-box-shadow-outside: var(--box-shadow-5)
$card-box-shadow-inside: -5px -5px 15px rgba(255, 255, 255, 0.88), inset -5px -5px 7px #FFFFFF, 5px 5px 15px rgba(218, 224, 233, 0.79), inset 5px 5px 7px #D1DAE8
$card-box-shadow-white: -4px -4px 5px rgba(255, 255, 255, 0.62), 1px 2px 8px #DAE0E9, inset 3px 3px 7px #052257, inset -4px -4px 7px #4C6EAF


// Animation time of click
$animationTime: .3s

// Transform amount of content when onClick
$transformAmount: 1px