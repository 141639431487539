@import "src/styles/variables"
@import "src/styles/breakpoints"

.modalContent
  padding: 50px 70px
  max-width: 550px
  box-sizing: border-box
  text-align: center
  h2
    font-size: 1.714rem
    text-shadow: var(--text-shadow-2)
    margin: 0

.modalButtons
  display: flex
  align-items: center
  margin-top: 30px

.modalButton
  width: 170px
  &:first-of-type
    margin-right: 20px
    z-index: 3
@include media('<=tablet')
  .modalContent
    padding: 50px 40px

@include media('<=Lphone')
  .modalContent
    padding: 40px 20px
    h2
      max-width: 250px
      margin-left: auto
      margin-right: auto
  .modalButtons
    flex-direction: column
    margin-top: 25px
  .modalButton
    width: 100%
    max-width: 260px
    &:first-of-type
      margin-right: 0
      margin-bottom: 16px