@import "../../styles/variables"

// Primary button and default styles
.button
  width: 100%
  position: relative
  border: none
  background-color: transparent
  display: inline-flex
  padding: 0
  border-radius: 8px
  outline: none
  color: #FFF
  font-weight: 600
  font-size: 1.143rem
  cursor: pointer
  z-index: 2
  min-width: 120px
  text-decoration: none
  // Borders
  &::after, &::before
    content: ''
    position: absolute
    transition: opacity $animationTime
    z-index: 1
    border-radius: 8px
  &::before
    top: -1px
    left: -1px
    right: -1px
    bottom: -1px
    background: var(--box-shadow-3)
    box-shadow: var(--box-shadow-2-outside)
    z-index: 1
  &:after
    top: 0
    left: 0
    width: 100%
    height: 100%
    box-shadow: var(--box-shadow-2-inside)
    opacity: 0
    z-index: 3
  // OnClick element
  &:active, &.iconedActive
    &::before
      opacity: var(--darkDisplayOpacity)
    &::after
      opacity: 1
    .content
      transform: translate($transformAmount, $transformAmount)

// Inner content of button
.content
  z-index: 2
  position: relative
  background: var(--wt-blue3-dt-blue12)
  line-height: 50px
  min-width: 100%
  box-sizing: border-box
  border-radius: 8px
  transition: transform $animationTime
  display: flex
  align-items: center
  justify-content: center

// Disabled button
.button:disabled
  cursor: not-allowed
  svg
    [fill="#163878"], [fill="#F3BA2F"], [fill="#8DC351"], [fill="#E38215"], [fill="#000"], [fill="#2775CA"], [fill="#010101"], [fill="#ff060a"]
      fill: #C2C6D3
  &::before
    background: var(--box-shadow-7)
    box-shadow: var(--box-shadow-8-outside)
  .content
    background: var(--border-3)
    color: var(--wt-gray8-dt-gray9)

// Secondary button
.secondary
  &::before
    background: var(--box-shadow-21)
    box-shadow: var(--box-shadow-22-outside)
  &::after
    box-shadow: var(--box-shadow-22-inside)
  .content
    background: var(--border-9)
    color: var(--wt-blue3-dt-white2)

// Secondary button
.ternary
  &::before
    background: var(--box-shadow-7)
    box-shadow: var(--box-shadow-8-outside)
  &::after
    box-shadow: var(--box-shadow-8-inside)
  .content
    background: var(--border-3)
    color: var(--wt-blue3-dt-white2)
      

// Vise and Coin payment buttons with icons
.iconed
  .content
    justify-content: space-between
    padding-left: 14px
  .text
    display: flex
    justify-content: center
    flex: 1
    padding-right: 34px
  &:active, &.iconedActive
    .circle
      &::before
        opacity: 0
      &::after
        opacity: 1
    .inner
      &::after
        opacity: 1

// Circle for Visa and Coin payment buttons
.circle
  width: 38px
  height: 38px
  display: flex
  border-radius: 50%
  position: relative
  &::before, &::after
    content: ''
    position: absolute
    border-radius: 50%
    transition: opacity $animationTime
  &::before
    top: 0
    left: 0
    width: 100%
    height: 100%
    box-shadow: var(--box-shadow-30-outside)
    z-index: -1
  &::after
    top: -1px
    left: -1px
    bottom: -1px
    right: -1px
    z-index: -1
    background: var(--box-shadow-7)
  .inner
    z-index: 1
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    background: var(--border-3)
    border-radius: 50%
    &::after
      position: absolute
      content: ''
      top: 0
      left: 0
      width: 100%
      height: 100%
      box-shadow: var(--box-shadow-30-inside)
      border-radius: 50%
      opacity: 0
      transition: opacity $animationTime

// Small button
.small
  font-size: 1rem
  .content
    min-width: 120px
    line-height: 38px
    width: 100%

// Middle sized button
.middle
  .content
    line-height: 43px
    min-width: 116px
    width: 100%

.superSmall
  width: 100%
  min-width: 0
  height: 100%
  &::before
    background: var(--border-12)
    box-shadow: var(--box-shadow-16)
  .content
    min-width: 0
    width: 100%
    height: 100%
    background: var(--wt-white2-dt-black2)
  .text
    line-height: 100%
    display: flex
    justify-content: center
    align-items: center

.social
  border-radius: 50%
  width: 100%
  min-width: 0
  height: 100%
  svg>path
    fill: var(--wt-blue16-dt-white2)
  .content
    min-width: 0
    width: 100%
    height: 100%
    position: absolute
    top: 0
    bottom: 0
  .text
    line-height: 100%
    display: flex
    justify-content: center
    align-items: center
  &::before, &::after
    border-radius: 50%
  &::before
    box-shadow: var(--box-shadow-29)
  .content
    border-radius: 50%

.flexible
  width: 100%
  min-width: 0
  height: 100%
  position: relative
  .content
    min-width: 0
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
  .text
    line-height: 100%
    display: flex
    justify-content: center
    align-items: center


.tabs
  &::before
    background: var(--border-14)
    box-shadow: var(--box-shadow-5)
  .content
    background: var(--wt-white2-dt-black2)
    width: 100%


// Download button
.download
  svg
    position: absolute
    top: 50%
    left: 15px
    transform: translateY(-50%)