@import "../../../../styles/variables"
@import "../../../../styles/breakpoints"

// Button
.NavButton
  padding: 12px 18px 10px 15px
  display: flex
  justify-content: space-between
  font-size: 1.143rem
  color: $text-light
  font-weight: 500
  margin-top: 2px
  margin-bottom: 2px
  cursor: pointer
  position: relative
  min-width: 207px
  box-sizing: border-box
  
  // Borders
  &::before, &::after
    content: ''
    position: absolute
    border-radius: 10px
    opacity: 0
    transition: opacity $animationTime
  &::before
    z-index: -1
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: var(--border-19)
  &::after
    background: var(--wt-white2-dt-black2)
    border: 3px solid var(--wt-white2-dt-black2)
    box-sizing: border-box
    box-shadow: var(--box-shadow-6)
    z-index: -1
    top: 1px
    left: 1px
    right: 1px
    bottom: 1px
  // Active and onClick
  &.active, &:active
    position: relative
    font-weight: bold
    color: var(--wt-blue3-dt-blue1)
    text-shadow: var(--text-shadow-3)
    &::before, &::after
      opacity: 1
    .Content
      transform: translate($transformAmount, $transformAmount)
    .Icon
      >img
        opacity: 0
      svg
        path
          fill: var(--wt-blue3-dt-blue1)
    .IconActive
      opacity: 1
    .Arrow
      opacity: 1
      svg
        path
          fill: var(--wt-blue3-dt-blue1)

.Last
  margin: 50px 6px 0 6px
  position: relative
  @include media("<=Ltablet")
    margin-top: 20px
  &::before
    position: absolute
    content: ''
    top: -20px
    left: 0
    width: 100%
    height: 1px
    background: #D8DFF3
    opacity: .5
    @include media("<=Ltablet")
      top: -10px

// Content
.Content
  display: flex
  align-items: center
  transition: transform $animationTime
  span
    white-space: nowrap
    max-width: 140px
    overflow: hidden
    text-overflow: ellipsis

// Icon wrapper
.Icon
  margin-right: 12px
  position: relative
  display: flex
  justify-content: center
  align-items: center

// Icon on active element and onClick
.IconActive
  position: absolute
  top: 0
  left: 0
  opacity: 0

// Right arrow on active element and onClick
.Arrow
  margin-left: 25px
  display: flex
  justify-content: center
  align-items: center
  opacity: 0
  transition: opacity $animationTime