@import "src/styles/breakpoints.scss"

.content
  padding: 0 40px 40px
  height: 100vh
  overflow-y: visible
  box-sizing: border-box
  padding-left: 310px
  &::after
    content: ""
    height: 40px
    position: absolute
    width: 1px

@media screen and ( min-width: 1560px )
  .content
    min-width: 1560px
    width: 1560px
    margin: 0 auto
    position: relative
    padding-left: 310px

@include media(">tablet")
  .statistic
    width: 90px

@include media("<=Ltablet")
  .content
    padding: 0 32px 40px


@include media("<=Lphone")
  .content
    padding: 0 16px 40px